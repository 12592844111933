import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import NavBar from "../nav/nav-bar";
import ReportNav from "./report-nav";
import { useState } from "react";
// import axios from "axios";
import Service from "../webservice/http";
import { useEffect } from "react";
import ForexViewmore from "./forexViewmore";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

function Agentscorecard() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  // const [open1, setopen1] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const services = new Service();
  const [id, setid] = useState("");
  function fetchData() {
    setLoading(true);
    services.get("api/report/agent_details/").then((res) => {
      console.log(res);
      setData(res);
      setLoading(false);
    });
  }
  // console.log(data);
  useEffect(() => {
    fetchData();
  }, []);

  console.log(data);
  const styles = {
    redIcon: {
      float: "inherit",
      color: "red",
    },
    greenIcon: {
      float: "inherit",
      color: "green",
    },
    alignTextCenter: {
      textAlign: "center",
    },
  };

  return (
    <div className="dashboard-body">
      <Header />
      <NavBar />

      <div>
        <div className="container-fluid">
          <div className="my-call">
            <div className="side-panel-with-table clearfix">
              <ReportNav />

              <div className="call-table">
                <div className="my-calls-column">
                  <div className="calls-top-pannel">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="pannel-heading clearfix">
                          <div className="pannel-heading-icon">
                            <i
                              className="fa fa-snowflake-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="pannel-heading-info">
                            <h3>Agents Scorecard </h3>
                            <p>Details</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex w-full mt-10 justify-between px-8 mb-8 ">
                  <div className="bg-white relative p-2 h-[90px]  flex   flex-col justify-center w-1/5 border border-gray-300 text-center mx-2 rounded overflow-hidden">
                    <div className="text-2xl">Total No of Calls</div>
                    <div className="text-base">
                      {data?.overall_info?.total_calls}
                    </div>
                    <div
                      className="absolute h-full w-1 left-0 top-0"
                      style={{ backgroundColor: "#EF2758" }}
                    ></div>
                  </div>
                  <div className="bg-white relative p-2 h-[90px]  flex   flex-col justify-center w-1/5 border border-gray-300 text-center mx-2 rounded overflow-hidden">
                    <div className="text-2xl">Avg answer speed</div>
                    <div className="text-base">
                      {data?.overall_info?.avg_answer_speed}
                    </div>
                    <div
                      className="absolute h-full w-1 left-0 top-0"
                      style={{ backgroundColor: "green" }}
                    ></div>
                  </div>
                  <div className="bg-white relative p-2 h-[90px]  flex   flex-col justify-center w-1/5 border border-gray-300 text-center mx-2 rounded overflow-hidden">
                    <div className="text-2xl">Abandoned calls</div>
                    <div className="text-base">
                      {data?.overall_info?.abandoned_calls}
                    </div>
                    <div
                      className="absolute h-full w-1 left-0 top-0"
                      style={{ backgroundColor: "#FFF974" }}
                    ></div>
                  </div>
                  <div className=" bg-white relative p-2 h-[90px]  flex   flex-col justify-center w-1/5 border border-gray-300 text-center mx-2 rounded overflow-hidden items-center ">
                    {/* <div className=' '> */}

                    <div className="text-2xl">Average calls/min:</div>
                    <div className="text-base">
                      {data?.overall_info?.avg_duration}
                    </div>
                    <div
                      className="absolute h-full w-1 left-0 top-0"
                      style={{ backgroundColor: "blue" }}
                    ></div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="flex  justify-end mb-10 mt-10">
                  <input
                    // onChange={(event) => debouncedSearch(event.target.value)}
                    type="text"
                    placeholder="Search..."
                    className="search-tag flex  justify-end"
                    style={{
                      // border: "1px solid transparent",
                      border: "none",
                      background: "#eef2f5",
                      borderRadius: "10px",
                      width: "250px",
                      outline: "none",
                      paddingLeft: "8px",
                      height: "35px",
                    }}
                  />
                </div>
                <table className="ss">
                  <thead>
                    <tr>
                      <th>
                        <p>Agent name</p>
                      </th>
                      <th>
                        <p>Total calls</p>
                      </th>
                      {/* <th>
                        <p>Product</p>
                      </th> */}
                      <th>
                        <p>Call answered</p>
                      </th>
                      <th>
                        <p>Language</p>
                      </th>
                      <th>
                        <p className="">Call resolution (%)</p>
                      </th>

                      <th>
                        <p>Intent %</p>
                      </th>

                      {/* <th>
                        <p>Agent Details</p>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {Loading && (
                      <tr>
                        <td colSpan="10" style={{ textAlign: "center" }}>
                          <ClipLoader color="#2056FF" size="50px" />
                        </td>
                      </tr>
                    )}
                    {data?.agents?.map((e, i) => (
                      <tr
                        onClick={() => {
                          // setOpen(true);
                          history.push(`/reports/agent-scorecard/${e?.id}`);
                          setid(e?.id);
                        }}
                        className="p-up"
                        key={i}
                      >
                        {/* <td>
                        <p className="blue">Forex</p>
                      </td> */}
                        <td>
                          <p>{e?.agent_name}</p>
                        </td>
                        <td>
                          <p>{e?.agent_total_calls}</p>
                        </td>
                        <td>
                          <p>{e?.calls_answered}</p>
                        </td>
                        <td>
                          <p>{e?.language}</p>
                        </td>
                        <td>
                          <p>{e?.call_resolution}</p>
                        </td>
                        <td>
                          <p>{e?.intent}</p>
                        </td>
                        {/* <td>
                          <p>***</p>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {open && <ForexViewmore orderId={id} setOpen={setOpen} />}
    </div>
  );
}

export default Agentscorecard;

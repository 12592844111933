import Header from "./header/header";
import Footer from "./footer/footer";
import NavBar from "./nav/nav-bar";
import "./live-call.css";
import { useEffect, useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import Service from "./webservice/http";
import ClipLoader from "react-spinners/ClipLoader";
import PulseLoader from "react-spinners/PulseLoader";
import { BiError, BiUserVoice } from "react-icons/bi";
import { RiSignalWifiErrorFill, RiUserVoiceFill } from "react-icons/ri";
import { MdOutlineVerifiedUser } from "react-icons/md";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import useWebSocket, { ReadyState } from "react-use-websocket";
import reactDom from "react-dom";
import ReactHtmlParser from "react-html-parser";
import useTimer from "easytimer-react-hook";
import { stringSimilarity } from "string-similarity-js";
//import reactDom from "react-dom";
import Notes from "./Notes";
import Dropdown from "rsuite/Dropdown";

import "rsuite/dist/rsuite.min.css";
import { Items } from "./Items";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import Filter from "./filter";
import { FaFilter } from "react-icons/fa";
import Profiling from "./profiling";
import { BiHistory } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import { FaTimes } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";
import data from "./dummydata";
import { useSelector, useDispatch } from "react-redux";
import sidebar, { makeVisible, makeVanish } from "./features/sidebar/sidebar";
import Head from "./sidebar-livecall/head";
import Nav from "./sidebar-livecall/nav";
import Masonry from "react-masonry-css";
import moment from "moment";

const LiveCall = () => {
  const currentDate = moment().format("MMM Do YY");
  const history = useHistory();
  const services = new Service();

  const [supportingInfo, setSupportingInfo] = useState(null);
  const [supportingInfoPending, setSupportingInfoPending] = useState();
  const [supportingInfoError, setSupportingInfoError] = useState();
  const [callType, setCallType] = useState("");
  const [product, setProduct] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [activeKey, setActiveKey] = useState("Product");
  const [productDetail, setProductDetail] = useState("");

  const dispatch = useDispatch();
  const sidebarVisiblity = useSelector((state) => state.visible.visible);

  // console.log(sidebarVisiblity);

  const agentRelationship = [
    {
      name: "Savings Account",
      check: true,
    },
    {
      name: "Checking Account",
      check: false,
    },
    {
      name: "Personal Loans",
      check: true,
    },
    {
      name: "Mortgages",
      check: false,
    },
    {
      name: "Business Accounts",
      check: false,
    },
    {
      name: "Credit Cards",
      check: true,
    },
    {
      name: "Investment Services",
      check: true,
    },
    {
      name: "Retirement Accounts",
      check: false,
    },
    {
      name: "Trust and Estate Services",
      check: true,
    },
    {
      name: "Safety Deposit Boxes",
      check: false,
    },
    {
      name: "Online Banking Services",
      check: true,
    },
  ];

  const fetchnextdropdown = function () {
    let url = `api/call_quality/manage_full_script/`;
    // if (product) {
    //   url = url + `?product_type=${product}`;
    // }
    // if (productType) {
    //   url = product
    //     ? url + `&product=${productType}`
    //     : url + `?product=${productType}`;
    // }
    services.get(url).then((res) => {
      console.log("lll", res);
      // setFullScript(res);
      setProductDetail(res);
    });
  };

  useEffect(() => {
    // fetchProduct();
    fetchnextdropdown();
  }, []);

  useEffect(() => {
    fetchSupportingInfo();
    fetchCI();
    fetchLiveCallURL();
  }, []);

  /**
   * Fetch live call supporting information which are required to process live call
   */
  function fetchSupportingInfo() {
    setSupportingInfoPending(true);
    setSupportingInfo(null);
    services.get("api/call/new_call/").then((res) => {
      // console.log(res);
      setSupportingInfoPending(false);
      if (res == "TypeError: Failed to fetch") {
        setSupportingInfoError("Connection Error");
      } else {
        if (res.code == "token_not_valid") {
          localStorage.clear();
          history.push("/login");
        }

        setSupportingInfo(res);
        setSupportingInfoError(null);
      }
    });
  }

  const [timer, isTargetAchieved] = useTimer({});
  const [callInitiate, setCallInitiate] = useState(false);
  const [callInitiatePending, setCallInitiatePending] = useState(false);

  const [notes, setNotes] = useState("");

  const [notesdata, setNotesData] = useState([
    { Notes: "Cross verify the email" },
    // { Notes: "Complies with data protection regulations" },
    { Notes: "Forgetting security information." },
  ]);

  // console.log(notesdata);
  const handleNotes = function () {
    const newArr = { Notes: notes };
    setNotesData((prevNotes) => [...prevNotes, newArr]);
    setNotes("");
  };

  // useEffect(() => {}, [notesdata]);

  /**
   *
   * @returns Initiate Live Call
   *
   */

  const [socketUrl, setSocketUrl] = useState(null);
  const [messageHistory, setMessageHistory] = useState([]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  const [transcriptionData, setTranscriptionData] = useState([]);
  const [transcriptionSentiment, setTranscriptionSentiment] = useState([]);
  const [transcriptionEntities, setTranscriptionEntities] = useState([]);

  const [receivingTranscription, setReceivingTranscription] = useState(false);
  const [inbound, setInbound] = useState(false);
  const [outbound, setOutbound] = useState(false);

  const lastSpeech = useRef(null);
  const lastSentiment = useRef(null);

  const [sentimentString, setSentiemntString] = useState("");
  const [foundConsumablesInsight, setFoundConsumablesInsight] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [callDuration, setCallDuration] = useState(null);
  const [edit, setEdit] = useState(false);

  // console.log(lastSpeech);
  /**
   * Manage transcription on receiving from websocket
   */
  useEffect(() => {
    // console.log(lastMessage);
    // lastSpeech.current?.scrollIntoView({ behavior: "smooth" });
    // lastSentiment.current?.scrollIntoView({ behavior: "smooth" });
    //console.log(timer.getTimeValues);
    //console.log(timer);
    try {
      var data = JSON.parse(lastMessage.data);
      // console.log(data.event);
      // console.log(data.text);

      var speechArray = transcriptionData;
      var sentimentArray = transcriptionSentiment;
      if (data.event === "interim-transcription") {
        //handleStart();
        setReceivingTranscription(true);
        if (speechArray.length === 0) {
          speechArray.push(data.text);
        } else {
          if (data.text.includes(speechArray[speechArray.length - 1])) {
            speechArray[speechArray.length - 1] = data.text;
          } else {
            speechArray.push(data.text);
          }
          if (speechArray.length > 1) {
            var last = speechArray[speechArray.length - 1].toLowerCase();
            var lastToLast = speechArray[speechArray.length - 2].toLowerCase();
            if (last.includes(lastToLast)) {
              speechArray[speechArray.length - 2] =
                speechArray[speechArray.length - 1];
              speechArray.splice(speechArray.length - 2, 1);
            } else {
              if (
                stringSimilarity(
                  speechArray[speechArray.length - 2],
                  speechArray[speechArray.length - 1]
                ) > 0.6
              ) {
                speechArray.splice(speechArray.length - 2, 1);
              }
            }
          }
        }
        sentimentArray.push(data.sentiment);
        setTranscriptionData(speechArray);
        setTranscriptionSentiment(sentimentArray);

        //Populate sentiment div
        // console.log(timer.getTimeValues().toString());
        if (data.sentiment >= 0)
          setSentiemntString(
            sentimentString +
              "<p><a style=color:green; font-size:20px;>" +
              timer.getTimeValues().toString() +
              " -  POSITIVE</a></p>"
          );
        else
          setSentiemntString(
            sentimentString +
              "<p><a style=color:red; font-size:20px;>" +
              timer.getTimeValues().toString() +
              " -  Negative</a></p>"
          );

        reactDom.render(
          ReactHtmlParser(sentimentString),
          document.getElementById("sentimentDiv")
        );

        //Entities
        var ciTemp = foundConsumablesInsight;
        // console.log(ciTemp);
        if (data.entities.length > 0) {
          var entitiesArray = transcriptionEntities;
          for (let i = 0; i < data.entities.length; i++) {
            if (entitiesArray.includes(data.entities[i].name) === false) {
              entitiesArray.push(data.entities[i].name);
              consumablesInsight.map((ci) => {
                // console.log("ci -", ci.keyword.toLowerCase());
                // console.log("en -", data.entities[i].name.toLowerCase());
                var found = false;
                for (let j = 0; j < ciTemp.length; j++) {
                  if (ci.keyword === ciTemp[j].keyword) {
                    found = true;
                    break;
                  }
                }
                if (found == false)
                  if (
                    data.entities[i].name
                      .toLowerCase()
                      .includes(ci.keyword.toLowerCase())
                  ) {
                    ciTemp.push(ci);
                  }
              });
            }
          }
          setTranscriptionEntities(entitiesArray);
          setFoundConsumablesInsight(ciTemp);
        }

        consumablesInsight.map((ciItem) => {
          if (data.text.toLowerCase().includes(ciItem.keyword.toLowerCase())) {
            if (!ciTemp.includes(ciItem)) {
              ciTemp.push(ciItem);
            }
          }
        });

        // consumablesInsight.map(ci => {
        //     if (data.text.toLowerCase().includes(ci.keyword.toLowerCase())) {
        //         if (ciTemp.includes(ci) === false) {
        //             ciTemp.push(ci)
        //         }
        //     }
        // })

        // setFoundConsumablesInsight(ciTemp);

        // var loan_found = false;
        // var quick_loan_found = false;
        // var default_found = false;
        // for (let i = 0; i < ciTemp.length; i++) {
        //     if (ciTemp[i]['keyword'].toLowerCase().includes('quick loan') === true) {
        //         quick_loan_found = true;
        //     }
        //     else if (ciTemp[i]['keyword'].toLowerCase().includes('loan') === true) {
        //         loan_found = true;
        //     }
        //     if (ciTemp[i]['keyword'].toLowerCase().includes('default') === true) {
        //         default_found = true;
        //     }
        // }
        // if (loan_found === true && default_found === false) {
        //     ciTemp.push({ 'keyword': 'default', 'link': '' })
        // }

        setFoundConsumablesInsight(ciTemp);

        lastSpeech.current?.scrollIntoView({ behavior: "smooth" });
        lastSentiment.current?.scrollIntoView({ behavior: "smooth" });
      } else if (data.event === "activity") {
        setReceivingTranscription(false);
        if (data.text === "Call Has Ended.") {
          setCallInitiate(false);
          setCallDuration(timer.getTimeValues().toString());
          //handleReset();
          timer.stop();
          timer.reset();
        }
        try {
          if (data.audio_url) {
            setAudioUrl(data.audio_url);
          }
        } catch {}
        lastSpeech.current?.scrollIntoView({ behavior: "smooth" });
        lastSentiment.current?.scrollIntoView({ behavior: "smooth" });
      }
      // console.log(data);
      lastSpeech.current?.scrollIntoView({ behavior: "smooth" });
      lastSentiment.current?.scrollIntoView({ behavior: "smooth" });
      findIntent();
    } catch (e) {
      // console.log(e);
    }
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory, socketUrl]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const [intent, setIntent] = useState(null);

  /**
   * Intent calculation on live call
   */
  function findIntent() {
    var positiveCount = 0;
    var negativeCount = 0;
    var sentimentArray = transcriptionSentiment;
    sentimentArray.map((sentiment) => {
      if (sentiment >= 0) {
        positiveCount++;
      } else {
        negativeCount++;
      }
    });
    if (positiveCount > negativeCount) {
      setIntent("Positive");
    } else if (negativeCount > positiveCount) {
      setIntent("Negative");
    } else {
      setIntent("Neutral");
    }
  }

  const [consumablesInsight, setConsumablesInsight] = useState(null);

  const [open, setOpen] = useState(false);

  /**
   * Find consumables insight from the live transcription
   */
  function fetchCI() {
    services.get("api/live_call/consumables_detail/").then((res) => {
      if (res == "TypeError: Failed to fetch") {
        NotificationManager.warning(
          "Warning",
          "Failed to load consumables insight !!!"
        );
      } else {
        if (res.code == "token_not_valid") {
          localStorage.clear();
          history.push("/login");
        }
        // console.log(res);
        setConsumablesInsight(res);
      }
    });
  }

  const [ngrokURL, setNgrokURL] = useState(null);
  var url = window.location.href;
  // url = url.replace(/^.*\/\/[^\/]+/, "");
  // console.log(url);

  /**
   * Fetch changable live call url
   */
  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  };
  function fetchLiveCallURL() {
    fetch(
      `${
        url.includes("dataklout.ai")
          ? " https://fb.dataklout.com/api/live_call/ngrok/"
          : "http://155.248.248.244:8017/cxlive/ngrok/"
      }`,
      {
        //
        http: headers,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          var url = result.ngrok_url;
          url = url.replace("http://", "");
          url = url.replace("https://", "");
          setNgrokURL(url);
          setSocketUrl(`wss://${url}/`);
        },
        (error) => {
          // console.log(error);
        }
      );
  }

  // console.log(ngrokURL);
  const initiateCall = () => {
    if (inbound == false && outbound == false) {
      NotificationManager.warning("Warning", "Please Select Call Type");
      return;
    }
    setCallInitiatePending(true);
    fetch(`https://${ngrokURL}/call`, { method: "GET" })
      .then((res) => {
        // console.log(res);
        if (res == "TypeError: Failed to fetch") {
          NotificationManager.error("Error", "Failed to Call initiate");
          setCallInitiatePending(false);
        }
        if (res.ok) {
          NotificationManager.success("Success", "Call Initiated");
          timer.start({});
          setCallInitiatePending(false);
          setCallInitiate(true);
          return res.json();
        } else {
          NotificationManager.error("Error", "Failed to Call initiate");
          setCallInitiatePending(false);
          throw Error("failed to initiate call");
        }
      })
      .then((data) => {})
      .catch((err) => {});
    const newTab = window.open(`https://${ngrokURL}/call`);
    setTimeout(() => {
      newTab.close();
    }, 1500);
  };

  /**
   *Save live call
   */
  const saveLiveCall = () => {
    var data = {
      customer_id: customerID,
      sentiment: intent,
      transcription: transcriptionData,
      call_type_id: callType,
      product_id: product,
      keywords: transcriptionEntities,
      audio_url: audioUrl,
      call_duration: callDuration,
      consumables_insight: foundConsumablesInsight,
    };
    // console.log(data);
    if (
      customerID === "" ||
      intent === "" ||
      transcriptionData.length == 0 ||
      callType === "" ||
      product === "" ||
      callDuration === "" ||
      callDuration === null
    ) {
      NotificationManager.error("Error", "Please Perform Call Properly");
      return;
    }

    if (audioUrl === "" || audioUrl === null) {
      NotificationManager.error(
        "Error",
        "Please wait till we process audio url"
      );
      return;
    }

    services.post("/api/live_call/add_new_call/", data).then((res) => {
      if (res == "TypeError: Failed to fetch") {
        NotificationManager.warning(
          "Warning",
          "Please Check your connection once !!!"
        );
      } else {
        if (res.code == "token_not_valid") {
          localStorage.clear();
          history.push("/login");
        }
        if (res.call_id) {
          NotificationManager.success(
            "Success",
            "Your Call Has been processed !!!"
          );
          setIntent(null);
          setTranscriptionData([]);
          setTranscriptionEntities([]);
          setAudioUrl(null);
          setCallDuration(null);
          setFoundConsumablesInsight([]);
          setSentiemntString("");

          setCallType("");
          setProduct("");
          setCustomerID("");
          fetchSupportingInfo();
        } else {
          NotificationManager.error("Error", res.error);
        }
      }
    });
  };

  useEffect(() => {
    try {
      reactDom.render(
        ReactHtmlParser(sentimentString),
        document.getElementById("sentimentDiv")
      );
    } catch {}
  }, [sentimentString]);
  const [sideBarWidth, setSideBarWidth] = useState(0);

  const changeSideBarWidth = () => {
    if (sideBarWidth == 440) {
      setSideBarWidth(0);
    } else {
      setSideBarWidth(440);
    }
  };

  useEffect(() => {}, [sidebarVisiblity]);

  // console.log(sidebarVisiblity);

  return (
    <div
      className="dashboard-body "
      id={`${sideBarWidth ? "pageContent" : ""}`}
    >
      {sidebarVisiblity ? <Head /> : <Header />}
      {sidebarVisiblity ? <Nav /> : <NavBar />}
      <a
        onClick={changeSideBarWidth}
        style={{
          position: "fixed",
          // transition: ".5s",
          // transitionTimingFunction: "linear",
          top: "30%",
          right: sideBarWidth,
          backgroundColor: "#e9be73",
          color: "white",
          // borderTopColor: "black",
          borderWidth: "2px",
          boxShadow: "6px 6px 3px #999",
          // paddingTop: "15px",
          // paddingLeft: "5px",
          // paddingRight: "5px",
          height: "45px",
          zIndex: "2",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "45px",
        }}
      >
        {sideBarWidth > 0 ? (
          <i
            class="fa fa-arrow-right"
            aria-hidden="true"
            style={{ fontSize: "30px" }}
            onClick={() => dispatch(makeVanish())}
          ></i>
        ) : (
          <i
            class="fa fa-arrow-left"
            fa-arrow-right
            aria-hidden="true"
            style={{ fontSize: "30px" }}
            onClick={() => dispatch(makeVisible())}
          ></i>
        )}

        {/* <i class="fa fa-history" aria-hidden="true"></i> */}
      </a>
      {!supportingInfoPending && (
        <Profiling
          width={sideBarWidth}
          changeSideBarWidth={changeSideBarWidth}
        />
      )}

      <NotificationContainer />

      <div id="test"></div>
      <section className="custom-wrapper-glife">
        {supportingInfo && (
          <div className="container-fluid" style={{ height: "900px" }}>
            <div
              className="my-call"
              style={{ boxShadow: "unset", background: "transparent" }}
            >
              <div
                className={`my-calls-column ${
                  sidebarVisiblity ? " mr-[53px] ml-[75px]" : ""
                }`}
                style={{
                  border: "none",
                  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                }}
              >
                <div className="calls-top-pannel">
                  <div className="row">
                    {/* <div className="col-lg-12">
                      <div
                        className="pannel-heading clearfix"
                        style={{ padding: 0 }}
                      >
                        <span>
                          <div className="pannel-heading-icon">
                            <i className="flaticon-incoming-call"></i>
                          </div>
                          <div className="pannel-heading-info">
                            <h4>Live Call </h4>
                          </div>
                        </span>
                        <div> hyhy</div>
                      </div>
                    </div> */}
                    <div className=" flex justify-between items-center">
                      <span className=" py-2 px-4 ">
                        <div className="pannel-heading-icon">
                          <i className="flaticon-incoming-call"></i>
                        </div>
                        <div className="pannel-heading-info mt-[7px]">
                          {localStorage.getItem("usecase") ===
                          "Complaints Management" ? (
                            <div className=" text-4xl font-bold ">
                              Live Complaint{" "}
                            </div>
                          ) : (
                            <div className=" text-4xl font-bold ">
                              Live Call{" "}
                            </div>
                          )}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
                // className=" "
                className={` bg-white mb-4 p-4 px-6 ${
                  sidebarVisiblity ? " mr-[53px] ml-[75px]" : ""
                }`}
              >
                <div className=" flex  mr-[40px]  ">
                  <span className=" mr-6">
                    <span className=" font-bold text-4xl">
                      <span className=" mr-4">
                        <i class="fa fa-user" aria-hidden="true"></i>
                      </span>
                      Mr Mark
                    </span>

                    <span className=" font-bold text-4xl whitespace-nowrap ml-6">
                      +919877465353
                    </span>
                  </span>
                </div>
              </div> */}
              <div
                className={`row ${
                  sidebarVisiblity ? "pl-[75px] pr-[53px]" : ""
                }`}
              >
                <div className="col-lg-5 mb-[25px]">
                  <div
                    className={`transcription-white-global pb-0 mb-0 ${
                      sidebarVisiblity ? "pt-6 pb-62" : ""
                    }`}
                  >
                    <div
                      className="transcription-box"
                      style={{ height: sidebarVisiblity ? "695px" : " 685px" }}
                    >
                      <div
                        className={`transcription-header clearfix ${
                          sidebarVisiblity ? "flex flex-col" : ""
                        }`}
                      >
                        <div className="pull-left">
                          <h4>Transcription</h4>
                        </div>
                        <div className="btn-group-callRecords pull-right">
                          <input
                            type="radio"
                            id="inbound"
                            name="inbound"
                            value="Inbound"
                            checked={inbound}
                            onChange={() => {
                              setInbound(true);
                              setOutbound(false);
                            }}
                          />
                          &nbsp;&nbsp;
                          <label
                            htmlFor="inbound"
                            style={{ paddingRight: "20px" }}
                          >
                            Inbound
                          </label>
                          <input
                            type="radio"
                            id="outbound"
                            name="outbound"
                            value="Outbound"
                            checked={outbound}
                            onChange={() => {
                              setInbound(false);
                              setOutbound(true);
                            }}
                          />
                          &nbsp;&nbsp;
                          <label
                            htmlFor="outbound"
                            style={{ paddingRight: "20px" }}
                          >
                            Outbound
                          </label>
                          {!callInitiate && !callInitiatePending && (
                            <button
                              className="btn btn-sm btn-info pull-right"
                              onClick={initiateCall}
                            >
                              Start Call
                            </button>
                          )}
                          {callInitiatePending && (
                            <button className="btn btn-sm btn-info">
                              Call Initiating
                            </button>
                          )}
                          {callInitiate && (
                            <button className="btn btn-sm btn-info">
                              Call Initiated
                            </button>
                          )}
                        </div>
                      </div>
                      <div
                        className="transcription-scrollable"
                        style={{ height: "470px" }}
                      >
                        <div id="transcriptionDiv">
                          {transcriptionData &&
                            transcriptionData.map(
                              (chat, index) =>
                                index != transcriptionData.length - 1 && (
                                  <div className="bubbleWrapper">
                                    <div className="inlineContainer other">
                                      <div style={{ marginTop: "15px" }}>
                                        <RiUserVoiceFill
                                          size="25px"
                                          color="green"
                                        />
                                      </div>
                                      <div className="otherBubble other">
                                        {chat}
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          {transcriptionData.length > 0 &&
                            !receivingTranscription && (
                              <div className="bubbleWrapper">
                                <div className="inlineContainer other">
                                  <div style={{ marginTop: "15px" }}>
                                    <RiUserVoiceFill
                                      size="25px"
                                      color="green"
                                    />
                                  </div>
                                  <div className="otherBubble other">
                                    {
                                      transcriptionData[
                                        transcriptionData.length - 1
                                      ]
                                    }
                                  </div>
                                </div>
                              </div>
                            )}
                          <div ref={lastSpeech} />
                        </div>
                      </div>
                      {receivingTranscription && (
                        <div>
                          <BiUserVoice size="20px" />
                          <a style={{ fontSize: "15px", color: "black" }}>
                            {transcriptionData[transcriptionData.length - 1]}{" "}
                          </a>
                          <PulseLoader size="5px" />
                        </div>
                      )}
                      {!receivingTranscription && callInitiate && (
                        <div>
                          <BiUserVoice size="20px" />
                          <a style={{ fontSize: "15px", color: "black" }}>
                            {" "}
                            &nbsp;Waiting For Transcription{" "}
                          </a>
                          <PulseLoader size="5px" />
                        </div>
                      )}
                    </div>
                    {localStorage.getItem("client") == "Hindustan Unilever" ? (
                      <Notes />
                    ) : null}
                  </div>

                  <div
                    className={`bg-[#f9fcff]${
                      sidebarVisiblity ? "ml-[90px] mr-0 bg-white" : ""
                    }`}
                    // style={{ minHeight: "150px" }}
                    style={{ minHeight: "150px" }}
                  >
                    <div className="p-6">
                      <div className="mb-3 text-4xl font-bold">
                        <span>
                          {" "}
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </span>{" "}
                        Agent Assist{" "}
                      </div>
                      <div style={{ overflowX: "scroll" }}>
                        <Table
                          striped="columns"
                          className=""
                          // style={{ tableLayout: "fixed" }}
                        >
                          <thead>
                            <tr className="assist">
                              {localStorage.getItem("usecase") ===
                              "Complaints Management" ? (
                                <th className=" whitespace-nowrap">
                                  Ticket Number
                                </th>
                              ) : (
                                ""
                              )}
                              <th>Customer</th>
                              {/* <th>Product</th> */}
                              <th>Account</th>
                              <th>NRIC</th>
                              {/* <th>Contact</th> */}
                              <th>Email </th>
                              <th style={{ width: "15%" }}>Address</th>
                              <th>DOB</th>
                              {/* <th>-</th> */}
                              {/* <th>-</th> */}
                              <th className=" whitespace-nowrap">
                                Relationship{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody className=" mt-5">
                            {data.data.map((e, i) => (
                              <tr key={i}>
                                {localStorage.getItem("usecase") ===
                                "Complaints Management" ? (
                                  <td>MW134443</td>
                                ) : (
                                  ""
                                )}
                                <td>
                                  <select name="" id="">
                                    <option value="" selected>
                                      Individual
                                    </option>
                                    <option value="">Joint</option>
                                    <option value="">Partnership firm</option>
                                    <option value="">Trusts</option>
                                    <option value="">Companies</option>
                                    ,, ,
                                  </select>
                                </td>
                                {/* <td>Forex</td> */}
                                <td>******7216</td>

                                <td>*****294J</td>
                                {/* <td>+60 2 688 7515</td> */}
                                <td>****428@gmail.com</td>
                                <td>
                                  No.3, Jalan Ceylon, Bukit Ceylon, 50200 Kuala
                                  Lumpur,Malaysia
                                </td>
                                {/* <td>{<FaTimes size="22" color="red" />}</td> */}

                                <td className=" font-extrabold">-</td>

                                <td className=" relative">
                                  <div className="hover-container">
                                    <input type="checkbox" checked />
                                    <span className="whitespace-nowrap">
                                      Business Accounts
                                    </span>
                                    <p
                                      className="text-base cursor-pointer text-blue-800 w-full"
                                      id="hover-trigger"
                                    >
                                      View more
                                    </p>
                                    <div
                                      id="hover-box"
                                      className="rounded-xl absolute right-[100%] p-4 bottom-[0%]"
                                    >
                                      <div className="flex flex-col">
                                        {agentRelationship.map((e, index) => (
                                          <div
                                            className="flex items-center"
                                            key={index}
                                          >
                                            <input
                                              type="checkbox"
                                              checked={e.check}
                                              disabled={!e.check}
                                            />
                                            <span className="whitespace-nowrap ml-3">
                                              {e.name}
                                            </span>{" "}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                {/* <td>-</td> */}
                              </tr>
                            ))}

                            {/*  */}
                            {/*  */}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>

                {localStorage.getItem("usecase") ===
                "Insurance - PCVC Verification" ? (
                  <div className="col-lg-7">
                    <div className="white-box-global ww-caps white-box-global-liveCallInsight">
                      <div
                        className="white-box-header"
                        style={{ border: "none" }}
                      >
                        {localStorage.getItem("usecase") ===
                        "Complaints Management" ? (
                          <h3>Ticket Insights</h3>
                        ) : (
                          <h3>Call Insights</h3>
                        )}
                      </div>
                      <div className="insights-body clearfix">
                        <div
                          className="row flex"
                          style={{ paddingBottom: "20px" }}
                        >
                          <div className="col-lg-4">
                            <div
                              className="insights-box insights-box2-liveCallInsight"
                              style={{ height: "250px" }}
                            >
                              <div className="insights-box-header clearfix">
                                <h4>Sentiment</h4>
                              </div>
                              <div
                                className="insights-box-count"
                                style={{ marginTop: "0px" }}
                              >
                                <div
                                  className="transcription-scrollable"
                                  style={{ height: "180px" }}
                                >
                                  <div
                                    id="sentimentDiv"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "20px",
                                    }}
                                  ></div>
                                  {/* {
                                                                    transcriptionSentiment &&
                                                                    transcriptionSentiment.map(sentiment => (
                                                                        <>
                                                                            <a>{formatTime()}</a>&nbsp;&nbsp;
                                                                            {
                                                                                sentiment >= 0 &&
                                                                                <a style={{ color: "green", font: "15px" }}>Positive</a>
                                                                            }
                                                                            {
                                                                                sentiment < 0 &&
                                                                                <a style={{ color: "red", font: "15px" }}>Negative</a>
                                                                            }
                                                                            <br />
                                                                        </>
                                                                    ))
                                                                } */}
                                  <div ref={lastSentiment} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div
                              className="insights-box insights-box2-liveCallInsight"
                              style={{ height: "250px" }}
                            >
                              <div className="insights-box-header clearfix">
                                <h4>Consumable Insight</h4>
                              </div>
                              <div
                                className="insights-box-count "
                                style={{ textAlign: "center" }}
                              >
                                {foundConsumablesInsight &&
                                  foundConsumablesInsight.map((fci) =>
                                    fci.link != "" ? (
                                      <>
                                        <a
                                          href={fci.link}
                                          target="_blank"
                                          style={{
                                            color: "green",
                                            fontSize: "15px",
                                          }}
                                        >
                                          {fci.keyword}
                                        </a>
                                        <br />
                                      </>
                                    ) : (
                                      <>
                                        <a style={{ fontSize: "15px" }}>
                                          {fci.keyword}
                                        </a>
                                        <br />
                                      </>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div
                              className="insights-box insights-box2-liveCallInsight"
                              style={{ height: "250px" }}
                            >
                              <div className="insights-box-header clearfix">
                                <h4>Verification Status</h4>
                              </div>
                              <div
                                className="insights-box-count"
                                style={{ textAlign: "center" }}
                              >
                                {intent === "Positive" && (
                                  <p
                                    style={{ color: "green", fontSize: "40px" }}
                                  >
                                    {intent}
                                  </p>
                                )}
                                {intent === "Negative" && (
                                  <p style={{ color: "red", fontSize: "40px" }}>
                                    {intent}
                                  </p>
                                )}
                                {intent === "Neutral" && (
                                  <p
                                    style={{ color: "blue", fontSize: "40px" }}
                                  >
                                    {intent}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <div
                              className="keyword-body"
                              style={{ height: "350px" }}
                            >
                              <div className="top-keywords-box-header clearfix">
                                <h4>Verification Details</h4>
                              </div>
                              <div className="keyword-body">
                                {transcriptionEntities.map((keyword) => (
                                  <h3>{keyword}</h3>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div
                              className="insights-box insights-box2-liveCallInsight"
                              style={{ height: "350px" }}
                            >
                              <div className="" style={{ padding: "1% 3%" }}>
                                {supportingInfo && (
                                  <div>
                                    <div
                                      className="row"
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <div className="form-col clearfix">
                                        <label>Script Type </label>
                                        <br />
                                        <select
                                          onChange={(e) =>
                                            setCallType(e.target.value)
                                          }
                                        >
                                          <option value=""></option>
                                          {supportingInfo.call_type.map(
                                            (callType) => (
                                              <option value={callType.id}>
                                                {callType.title}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    <div
                                      className="row"
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <div className="form-col clearfix ">
                                        <label>Product </label>
                                        <div className="dropdown-button">
                                          <Dropdown
                                            noCaret
                                            title={activeKey}
                                            style={{
                                              zIndex: "0",

                                              border:
                                                " 1px solid rgba(61, 61, 61, 0.5);",
                                              width: "100%",
                                              height: "44px",
                                              background: "#e8ebef",
                                              borderRadius: "2px",
                                              fontWeight: "normal",
                                              fontSize: "16px",
                                              letterSpacing: "0.095em",
                                              color: "#2f3c4d",

                                              outline: "none",
                                              textAlign: "initial",
                                            }}
                                            id="mydropdown"
                                            size="lg"
                                            placement="leftEnd"
                                            activeKey={activeKey}
                                            onSelect={(eventKey) =>
                                              setActiveKey(eventKey)
                                            }
                                          >
                                            {Items.map((e) => (
                                              <Dropdown.Menu
                                                title={e.title}
                                                eventKey={e.title}
                                              >
                                                {e.submenu.map((e) => (
                                                  <Dropdown.Item
                                                    eventKey={e.title}
                                                    key={e.title}
                                                  >
                                                    {e.title}
                                                  </Dropdown.Item>
                                                ))}
                                              </Dropdown.Menu>
                                            ))}
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="row"
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <div className="form-col clearfix">
                                        <label>Customer </label>
                                        <br />
                                        <select
                                          onChange={(e) =>
                                            setCustomerID(e.target.value)
                                          }
                                        >
                                          <option value=""></option>
                                          {supportingInfo.customer.map(
                                            (customerItem) => (
                                              <option value={customerItem.id}>
                                                {customerItem.salutation}{" "}
                                                {customerItem.first_name}{" "}
                                                {customerItem.middle_name}{" "}
                                                {customerItem.last_name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    <div
                                      className="row"
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "10px",
                                        paddingTop: "none",
                                      }}
                                    >
                                      <div
                                        className={`popup-footer ${
                                          sidebarVisiblity ? "m-0" : ""
                                        }`}
                                      >
                                        <button
                                          className="btn Save pull-right"
                                          type="button"
                                          onClick={saveLiveCall}
                                        >
                                          {" "}
                                          Save{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className=" flex ">
                      <div
                        className={` h-[238px] bg-white relative  w-[54%] mr-6 ${
                          sidebarVisiblity ? " mb-[49px] w-[54%]]" : ""
                        }`}
                      >
                        <div className="p-6 ">
                          <div className=" flex justify-between">
                            <div className="mb-3  text-4xl font-bold">
                              {" "}
                              <i
                                class="fa fa-envelope"
                                aria-hidden="true"
                                style={{ marginRight: "10px" }}
                              ></i>
                              Email{" "}
                            </div>
                            {/* <select
                              style={{ border: "1px solid gray" }}
                              className=" w-[200px] h-10 rounded-lg hover:bg-gray-100"
                            >
                              <option value="">Credit Card </option>
                              <option value="">Savings Account</option>
                              <option value="">EMI Plan</option>
                            </select> */}

                            <select
                              // onChange={(e) => setProductType(e.target.value)}
                              style={{ borderBottom: "1px solid lightgray" }}
                              // value={productType}
                            >
                              <option value="" selected disabled>
                                Select Product
                              </option>

                              {console.log(
                                "llllllllllllllllllllllllllllll",
                                productDetail
                              )}

                              {productDetail &&
                                productDetail &&
                                productDetail?.map((product) => (
                                  <option
                                    key={product?._product}
                                    value={product?._product}
                                  >
                                    {product?._product
                                      .toLowerCase()
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}
                                  </option>
                                ))}
                            </select>
                          </div>

                          <textarea
                            id="w3review"
                            name="w3review"
                            rows="5"
                            cols={sidebarVisiblity ? "45" : "60"}
                          >
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Dicta distinctio impedit ullam quo. Enim sed
                            eius omnis, rerum reprehenderit perspiciatis
                            temporibus corporis. Consequatur fuga id veniam
                            reiciendis vero cumque autem corrupti, repudiandae,
                            temporibus repellat eligendi.
                          </textarea>

                          {/* <p className=" flex justify-end">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Dicta distinctio impedit ullam quo. Enim sed
                            eius omnis, rerum reprehenderit perspiciatis
                            temporibus corporis. Consequatur fuga id veniam
                            reiciendis vero cumque autem corrupti, repudiandae,
                            temporibus repellat eligendi.
                          </p> */}
                        </div>
                        <div className=" flex justify-end  absolute bottom-3 right-6">
                          <button
                            className=" px-[40px] py-4 bg-[#271078]  text-white "
                            // style={{ border: "1px solid red" }}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                      <div
                        className={`bg-white  w-[44%] relative ${
                          sidebarVisiblity ? "mb-[60px] h-[239px]" : ""
                        }`}
                        // style={{ minHeight: "100px" }}
                      >
                        <div className="px-[25px] py-[15px]">
                          <div className="mb-3 flex items-center text-4xl font-bold">
                            {" "}
                            <img
                              src="assets/images/notes.png"
                              className="img-responsive h-[20px] w-[20px] mr-2"
                              alt=""
                            />
                            Notes
                          </div>

                          <div>
                            <ul
                              className=" px-4"
                              style={{ whiteSpace: "normal" }}
                            >
                              {notesdata.map((e, i) => (
                                <li
                                  className="list-disc text-2xl  whitespace-normal  break-words"
                                  key={i}
                                >
                                  {" "}
                                  {e.Notes}
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* <div className="accordion" style={{ width: "99%" }}>
                            <Masonry
                              breakpointCols={4}
                              className="my-masonry-grid"
                              columnClassName="my-masonry-grid_column"
                            >
                              {notesdata.map((e) => (
                                <div
                                  class=""
                                  style={{
                                    paddingLeft: "5px",
                                    paddingTop: "5px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        border: "1px solid grey",
                                        padding: "10px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <i
                                        style={{
                                          paddingLeft: "5px",
                                          fontSize: "20px",
                                          color: "#1f4185",
                                          transform: "rotate(45deg)",
                                        }}
                                        className="fa fa-thumb-tack"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;&nbsp;<label>{e.title}</label>
                                      <i
                                        className="fa fa-trash pull-right"
                                        style={{
                                          paddingTop: "5px",
                                          paddingRight: "5px",
                                          cursor: "pointer",
                                        }}
                                        aria-hidden="true"
                                      ></i>
                                      <p
                                        style={{
                                          paddingTop: "0px",
                                          paddingLeft: "10px",
                                          paddingRight: "10px",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {e.Notes}
                                      </p>
                                      <div class="row">
                                        <div class="col-md-6">
                                          <a
                                            style={{
                                              color: "black",
                                              fontSize: "10px",
                                              paddingTop: "5px",
                                              paddingLeft: "10px",
                                              paddingBottom: "5px",
                                            }}
                                          >
                                            <b>{currentDate}</b>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Masonry>
                          </div> */}
                          <div
                            className="flex items-center"
                            style={{
                              paddingLeft: "0px",
                              paddingRight: "0px",
                              marginTop: "20px",
                              // marginLeft: "5px",
                              width: "50%",
                              position: "absolute",
                              bottom: "10px",
                            }}
                          >
                            <div
                              className={`${
                                sidebarVisiblity
                                  ? "w-[168px] mr-[2x] ml-[-14px]"
                                  : ""
                              }`}
                              style={{
                                border: "1px solid grey",
                                padding: "8px",
                                borderRadius: "10px",
                                marginRight: sidebarVisiblity ? "5px" : "10px",
                              }}
                            >
                              <input
                                type="text"
                                placeholder="Add New Notes..."
                                value={notes}
                                onChange={(e) => {
                                  setNotes(e.target.value);
                                }}
                              />
                            </div>
                            {sidebarVisiblity ? (
                              <div
                                className="  rounded-full bg-[#271078] text-white text-center p-[4px] cursor-pointer"
                                onClick={handleNotes}
                              >
                                +
                              </div>
                            ) : (
                              <button
                                className="p-[9px] px-[14px]  whitespace-nowrap ml-2 text-white bg-[#271078] flex  justify-center items-center "
                                // style={{ border: "1px solid grey" }}
                                onClick={handleNotes}
                              >
                                <span>Add Notes</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                ) : (
                  <div className="col-lg-7">
                    <div className="white-box-global ww-caps white-box-global-liveCallInsight">
                      <div
                        className="white-box-header"
                        style={{ border: "none" }}
                      >
                        {localStorage.getItem("usecase") ===
                        "Complaints Management" ? (
                          <h3>Ticket Insights</h3>
                        ) : (
                          <h3>Call Insights</h3>
                        )}
                      </div>
                      <div className="insights-body clearfix ">
                        <div
                          className="row flex"
                          style={{ paddingBottom: "20px" }}
                        >
                          <div className="col-lg-4">
                            <div
                              className="insights-box insights-box2-liveCallInsight"
                              style={{ height: "250px" }}
                            >
                              <div className="insights-box-header clearfix">
                                <h4>Sentiment</h4>
                              </div>
                              <div
                                className="insights-box-count"
                                style={{ marginTop: "0px" }}
                              >
                                <div
                                  className="transcription-scrollable"
                                  style={{ height: "180px" }}
                                >
                                  <div
                                    id="sentimentDiv"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "20px",
                                    }}
                                  ></div>
                                  {/* {
                                                                    transcriptionSentiment &&
                                                                    transcriptionSentiment.map(sentiment => (
                                                                        <>
                                                                            <a>{formatTime()}</a>&nbsp;&nbsp;
                                                                            {
                                                                                sentiment >= 0 &&
                                                                                <a style={{ color: "green", font: "15px" }}>Positive</a>
                                                                            }
                                                                            {
                                                                                sentiment < 0 &&
                                                                                <a style={{ color: "red", font: "15px" }}>Negative</a>
                                                                            }
                                                                            <br />
                                                                        </>
                                                                    ))
                                                                } */}
                                  <div ref={lastSentiment} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div
                              className="insights-box insights-box2-liveCallInsight"
                              style={{ height: "250px" }}
                            >
                              <div className="insights-box-header clearfix">
                                <h4>Consumable Insight</h4>
                              </div>
                              <div
                                className="insights-box-count "
                                style={{ textAlign: "center" }}
                              >
                                {foundConsumablesInsight &&
                                  foundConsumablesInsight.map((fci) =>
                                    fci.link != "" ? (
                                      <>
                                        <a
                                          href={fci.link}
                                          target="_blank"
                                          style={{
                                            color: "green",
                                            fontSize: "15px",
                                          }}
                                        >
                                          {fci.keyword}
                                        </a>
                                        <br />
                                      </>
                                    ) : (
                                      <>
                                        <a style={{ fontSize: "15px" }}>
                                          {fci.keyword}
                                        </a>
                                        <br />
                                      </>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div
                              className="insights-box insights-box2-liveCallInsight"
                              style={{ height: "250px" }}
                            >
                              <div className="insights-box-header clearfix">
                                <h4>Intent</h4>
                              </div>
                              <div
                                className="insights-box-count"
                                style={{ textAlign: "center" }}
                              >
                                {intent === "Positive" && (
                                  <p
                                    style={{ color: "green", fontSize: "40px" }}
                                  >
                                    {intent}
                                  </p>
                                )}
                                {intent === "Negative" && (
                                  <p style={{ color: "red", fontSize: "40px" }}>
                                    {intent}
                                  </p>
                                )}
                                {intent === "Neutral" && (
                                  <p
                                    style={{ color: "blue", fontSize: "40px" }}
                                  >
                                    {intent}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <div
                              className="keyword-body"
                              style={{ height: "350px" }}
                            >
                              <div className="top-keywords-box-header clearfix">
                                <div className=" text-4xl font-bold">
                                  Top Keywords
                                </div>
                              </div>
                              <div className="keyword-body">
                                {transcriptionEntities.map((keyword) => (
                                  <h3>{keyword}</h3>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div
                              className="insights-box insights-box2-liveCallInsight"
                              style={{ height: "350px" }}
                            >
                              <div className="" style={{ padding: "1% 3%" }}>
                                {supportingInfo && (
                                  <div>
                                    <div
                                      className="row"
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <div className="form-col clearfix">
                                        {localStorage.getItem("usecase") ===
                                        "Complaints Management" ? (
                                          <label>Product</label>
                                        ) : (
                                          <label>Call Type </label>
                                        )}
                                        <br />
                                        <select
                                          onChange={(e) =>
                                            setCallType(e.target.value)
                                          }
                                        >
                                          <option value=""></option>
                                          {supportingInfo.call_type.map(
                                            (callType) => (
                                              <option value={callType.id}>
                                                {callType.title}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    <div
                                      className="row"
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <div className="form-col clearfix">
                                        {localStorage.getItem("usecase") ===
                                        "Complaints Management" ? (
                                          <label>Services</label>
                                        ) : (
                                          <label>Product </label>
                                        )}
                                        <br />
                                        <select
                                          onChange={(e) =>
                                            setProduct(e.target.value)
                                          }
                                        >
                                          <option value=""></option>
                                          {supportingInfo.product.map(
                                            (product) => (
                                              <option value={product.id}>
                                                {product.title}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    <div
                                      className="row"
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <div className="form-col clearfix">
                                        <label>Customer </label>
                                        <br />
                                        <select
                                          onChange={(e) =>
                                            setCustomerID(e.target.value)
                                          }
                                        >
                                          <option value=""></option>
                                          {supportingInfo.customer.map(
                                            (customerItem) => (
                                              <option value={customerItem.id}>
                                                {customerItem.salutation}{" "}
                                                {customerItem.first_name}{" "}
                                                {customerItem.middle_name}{" "}
                                                {customerItem.last_name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    <div
                                      className="row"
                                      style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "10px",
                                        paddingTop: "none",
                                      }}
                                    >
                                      <div className="popup-footer">
                                        <button
                                          className="btn Save pull-right"
                                          type="button"
                                          onClick={saveLiveCall}
                                        >
                                          {" "}
                                          Save{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className=" flex ">
                      <div
                        className={` h-[238px] bg-white relative  w-[54%] mr-6 ${
                          sidebarVisiblity ? " mb-[49px] w-[54%]]" : ""
                        }`}
                      >
                        <div className="p-6 ">
                          <div className=" flex justify-between">
                            <div className="mb-3  text-4xl font-bold">
                              {" "}
                              <i
                                class="fa fa-envelope"
                                aria-hidden="true"
                                style={{ marginRight: "10px" }}
                              ></i>
                              Email{" "}
                            </div>
                            <select
                              style={{ border: "1px solid gray" }}
                              className=" w-[200px] h-10 rounded-lg hover:bg-gray-100"
                            >
                              <option value="">Credit Card </option>
                              <option value="">Savings Account</option>
                              <option value="">EMI Plan</option>
                            </select>
                          </div>

                          <textarea
                            id="w3review"
                            name="w3review"
                            rows="5"
                            cols={sidebarVisiblity ? "45" : "60"}
                          >
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Dicta distinctio impedit ullam quo. Enim sed
                            eius omnis, rerum reprehenderit perspiciatis
                            temporibus corporis. Consequatur fuga id veniam
                            reiciendis vero cumque autem corrupti, repudiandae,
                            temporibus repellat eligendi.
                          </textarea>

                          {/* <p className=" flex justify-end">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Dicta distinctio impedit ullam quo. Enim sed
                            eius omnis, rerum reprehenderit perspiciatis
                            temporibus corporis. Consequatur fuga id veniam
                            reiciendis vero cumque autem corrupti, repudiandae,
                            temporibus repellat eligendi.
                          </p> */}
                        </div>
                        <div className=" flex justify-end  absolute bottom-3 right-6">
                          <button
                            className=" px-[40px] py-4 bg-[#271078]  text-white "
                            // style={{ border: "1px solid red" }}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                      <div
                        className={`bg-white  w-[44%] relative ${
                          sidebarVisiblity ? "mb-[60px] h-[239px]" : ""
                        }`}
                        // style={{ minHeight: "100px" }}
                      >
                        <div className="px-[25px] py-[15px]">
                          <div className="mb-3 flex items-center text-4xl font-bold">
                            {" "}
                            <img
                              src="assets/images/notes.png"
                              className="img-responsive h-[20px] w-[20px] mr-2"
                              alt=""
                            />
                            Notes
                          </div>

                          <div>
                            <ul
                              className=" px-4"
                              style={{ whiteSpace: "normal" }}
                            >
                              {notesdata.map((e, i) => (
                                <li
                                  className="list-disc text-2xl  whitespace-normal  break-words"
                                  key={i}
                                >
                                  {" "}
                                  {e.Notes}
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* <div className="accordion" style={{ width: "99%" }}>
                            <Masonry
                              breakpointCols={4}
                              className="my-masonry-grid"
                              columnClassName="my-masonry-grid_column"
                            >
                              {notesdata.map((e) => (
                                <div
                                  class=""
                                  style={{
                                    paddingLeft: "5px",
                                    paddingTop: "5px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        border: "1px solid grey",
                                        padding: "10px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <i
                                        style={{
                                          paddingLeft: "5px",
                                          fontSize: "20px",
                                          color: "#1f4185",
                                          transform: "rotate(45deg)",
                                        }}
                                        className="fa fa-thumb-tack"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;&nbsp;<label>{e.title}</label>
                                      <i
                                        className="fa fa-trash pull-right"
                                        style={{
                                          paddingTop: "5px",
                                          paddingRight: "5px",
                                          cursor: "pointer",
                                        }}
                                        aria-hidden="true"
                                      ></i>
                                      <p
                                        style={{
                                          paddingTop: "0px",
                                          paddingLeft: "10px",
                                          paddingRight: "10px",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {e.Notes}
                                      </p>
                                      <div class="row">
                                        <div class="col-md-6">
                                          <a
                                            style={{
                                              color: "black",
                                              fontSize: "10px",
                                              paddingTop: "5px",
                                              paddingLeft: "10px",
                                              paddingBottom: "5px",
                                            }}
                                          >
                                            <b>{currentDate}</b>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Masonry>
                          </div> */}
                          <div
                            className="flex items-center"
                            style={{
                              paddingLeft: "0px",
                              paddingRight: "0px",
                              marginTop: "20px",
                              // marginLeft: "5px",
                              width: "50%",
                              position: "absolute",
                              bottom: "10px",
                            }}
                          >
                            <div
                              className={`${
                                sidebarVisiblity
                                  ? "w-[168px] mr-[2x] ml-[-14px]"
                                  : ""
                              }`}
                              style={{
                                border: "1px solid grey",
                                padding: "8px",
                                borderRadius: "10px",
                                marginRight: sidebarVisiblity ? "5px" : "10px",
                              }}
                            >
                              <input
                                type="text"
                                placeholder="Add New Notes..."
                                value={notes}
                                onChange={(e) => {
                                  setNotes(e.target.value);
                                }}
                              />
                            </div>
                            {sidebarVisiblity ? (
                              <div
                                className="  rounded-full bg-[#271078] text-white text-center p-[4px] cursor-pointer"
                                onClick={handleNotes}
                              >
                                +
                              </div>
                            ) : (
                              <button
                                className="p-[9px] px-[14px]  whitespace-nowrap ml-2 text-white bg-[#271078] flex  justify-center items-center "
                                // style={{ border: "1px solid grey" }}
                                onClick={handleNotes}
                              >
                                <span>Add Notes</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                )}
              </div>

              <div
                className=" mt-4 h-[20px]"
                // style={{ border: "1px solid red" }}
              >
                {" "}
              </div>
            </div>
          </div>
        )}

        {supportingInfoPending && (
          <div className="empty-call">
            <ClipLoader color="#2056FF" size="50px" />
          </div>
        )}

        {supportingInfoError && (
          <div className="empty-call">
            <p style={{ fontSize: "25px", color: "#FF8520" }}>
              {supportingInfoError === "Connection Error" && (
                <RiSignalWifiErrorFill />
              )}
              {supportingInfoError !== "Connection Error" && <BiError />}
              {supportingInfoError}
            </p>
          </div>
        )}
      </section>

      {/* <Footer /> */}
    </div>
  );
};
export default LiveCall;

import React from "react";
import Modal from "react-modal";

function Viewmore({ formData, showNewModal, setShowNewModal, setFormData }) {
  // console.log(formData);

  return (
    <Modal
      isOpen={showNewModal}

      // style={customStyles}
    >
      <div className="col-md-12">
        <div className="pannel-heading clearfix">
          <div className="pannel-heading-icon">
            <i className="fa fa-snowflake-o" aria-hidden="true"></i>
          </div>
          <div className="pannel-heading-info">
            <h3>Detailed Report</h3>
          </div>
        </div>
        <div
          className=" absolute top-[-13px] right-[-9px]  cursor-pointer"
          onClick={() => {
            setShowNewModal(false);
            setFormData([]);
          }}
        >
          <i
            class="fa fa-times-circle"
            aria-hidden="true"
            style={{ fontSize: "25px" }}
          ></i>
        </div>

        <div className="bg-gray-100 flex space-x-4 p-6">
          <div className="w-full">
            <div className="bg-white rounded p-4 pt-6">
              <div className="px-2 flex">
                <div className="w-1/2 flex text-sm border-r border-gray-300">
                  <div className="date">
                    <div className="flex text-2xl pt-2 flex-col text-gray font-bold">
                      <div className="mb-4">
                        Report:
                        <span className="text-black ml-4">
                          {formData?.report_date}
                        </span>
                      </div>
                      <div className="mb-4">
                        Call:
                        <span className="text-black ml-4">
                          {formData?.call_date}
                        </span>
                      </div>
                      <div className="">
                        Audit:
                        <span className="text-black ml-4">
                          {formData?.audit_date}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-1/2 pl-6 flex text-2xl text-gray font-bold mt-2">
                  <div className="w-1/2 max-w-[120px]">
                    <div className="mr-2">NEMP ID:</div>
                    <div className="mr-2 mt-3">Application No:</div>
                    <div className="mr-2 mt-3">Language:</div>
                  </div>
                  <div className="w-1/2">
                    <div className="text-black ml-4">{formData?.nemp_id}</div>
                    <div className="text-black ml-4 mt-3">
                      {formData?.application_no}
                    </div>
                    <div className="text-black ml-4 mt-3">
                      {formData?.language}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="py-2 flex"></div> */}
              <div className="px-2 itemDetails border-t mt-4 pt-4">
                <div className="flex">
                  <div className="w-1/2 border-r border-gray-300 text-2xl text-gray font-bold">
                    <div className="mb-2">
                      Opening:{" "}
                      <span className="text-black">
                        {formData?.call_opening}
                      </span>
                    </div>
                    <div className="mb-2">
                      RPC:{" "}
                      <span className="text-black">
                        {formData?.rpc ? "Yes" : "No"}
                      </span>
                    </div>
                    <div className="mb-2">
                      NRPC:{" "}
                      <span className="text-black">
                        {formData?.nrpc ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                  <div className="w-1/2 pl-6 flex text-2xl text-gray font-bold mt-2">
                    <div className="">
                      <div className="mr-2">Language Preference:</div>
                      <div className="mr-2 mt-3">Welcoming the Customer:</div>
                      <div className="mr-2 mt-3">Time Affirmation:</div>
                    </div>

                    <div className="w-1/2">
                      <div className="text-black ml-4">
                        {formData?.language_preference}
                      </div>
                      <div className="text-black ml-4 mt-3">
                        {formData?.welcoming_customer ? "Yes" : "No"}
                      </div>
                      <div className="text-black ml-4 mt-3">
                        {formData?.time_affermation ? "Yes" : "No"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded px-4 pt-4 pb-3 mt-6">
              <div className="px-2 flex mb-1 justify-between">
                <div className="flex flex-col w-1/2 border-r">
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          Hold Procedure:{" "}
                          <span className=" text-black ml-4"></span>{" "}
                          {formData?.hold_procedure ? "Yes" : "No"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          Senior Citizen:{" "}
                          <span className=" text-black ml-4"></span>{" "}
                          {formData?.sr_citizen_confirmation ? "Yes" : "No"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-1/2 pl-6">
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          Personal Information:{" "}
                          <span className=" text-black ml-4">
                            {formData?.personal_info_sharing_clause
                              ? "Yes"
                              : "No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          2nd attempt of concern case:{" "}
                          <span className=" text-black ml-4">
                            {formData?.second_attempt_concern ? "Yes" : "No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* HS_ HS_Sr.Citizen HS_Personal Information */}
              <hr />

              <div className="px-2 flex mb-1 justify-between">
                <div className="flex flex-col w-1/2 border-r">
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          Purpose of insurance:{" "}
                          <span className=" text-black ml-4">
                            {" "}
                            {formData?.purpose_of_insurance ? "Yes" : "No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          Thanking on confirmation:{" "}
                          <span className=" text-black ml-4">
                            {formData.thankingOnConfirmation}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-1/2 pl-6">
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          AVF confirmation:{" "}
                          <span className=" text-black ml-4">
                            {formData?.avf_confirmation ? "Yes" : "No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          (Advisor) Application Number:
                          <span className=" text-black ml-4">
                            {formData?.advisor_application_number
                              ? "Yes"
                              : "No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className="bg-white rounded px-4 pt-4 pb-3 mt-6">
              <div className="px-2 flex mb-1 justify-between">
                <div className="flex flex-col w-1/2 border-r">
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          Vesting plan:{" "}
                          <span className=" text-black ml-4">
                            {" "}
                            {formData?.vesting_plan ? "Yes" : "No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          Conversion:{" "}
                          <span className=" text-black ml-4">
                            {" "}
                            {formData?.conversion ? "Yes" : "No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-1/2 pl-6">
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          Exit clause:{" "}
                          <span className=" text-black ml-4">
                            {" "}
                            {formData?.exit_clause ? "Yes" : " No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          Policy Document:{" "}
                          <span className=" text-black ml-4">
                            {" "}
                            {formData?.policy_document ? "Yes" : " No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* HS_ HS_Sr.Citizen HS_Personal Information */}
              <hr />

              <div className="px-2 flex mb-1 justify-between">
                <div className="flex flex-col w-1/2 border-r">
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          My Account:{" "}
                          <span className=" text-black ml-4">
                            {" "}
                            {formData?.my_account_details ? "Yes" : "No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          Disclaimer:{" "}
                          <span className=" text-black ml-4">
                            {" "}
                            {formData?.disclaimer ? "Yes" : "No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-1/2 pl-6">
                  <div className="w-full flex text-sm border-gray-800">
                    <div className="date">
                      <div className="flex items-center text-2xl py-2 font-bold text-gray">
                        <div className="">
                          Probing:{" "}
                          <span className=" text-black ml-4">
                            {formData?.probing ? "Yes" : "No"}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>

        <div className="popup-footer">
          <>
            <button
              className="btn"
              style={{ padding: "9px" }}
              type="button"
              onClick={() => setShowNewModal(false)}
            >
              Close
            </button>
            {/* <button className="btn" style={{ padding: "9px" }} type="button">
              Send Email
            </button> */}
          </>
        </div>
      </div>
    </Modal>
  );
}

export default Viewmore;
